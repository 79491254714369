<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="d-flex flex-wrap space-between">
        <div class="mb-2 col-md-9 col-12">
          <b-card-title>Багийн гишүүдийн жагсаалт</b-card-title>
          <b-card-sub-title>Тухайн хэрэглэгчид харьяалагдах гишүүд</b-card-sub-title>
        </div>
        <div class="mb-1 col-md-3 col-12" v-if="action">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-login
            variant="outline-primary"
          >
            Гишүүд нэмэх
          </b-button>
        </div>
      </div>
    </b-card-body>
    <b-table
    responsive
    :items="userData.children"
    :fields="fields"
    class="mb-0"
  >
    <template #cell(Phone)="data">
      <span class="text-nowrap">
        {{ data.value }}
      </span>
    </template>

    <!-- Optional default data cell scoped slot -->
    <template #cell()="data">
      {{ data.value }}
    </template>
    <!-- Column: is_active -->
    <template #cell(is_active)="data">
      <b-badge
        pill
        :variant="data.item.is_active ? 'success': 'danger'"
      >
        {{ data.item.is_active ? 'идэвхтэй': 'идэвхгүй' }}
      </b-badge>
    </template>
    <!-- Column: Actions -->
        <!-- <template #cell(actions)="data" v-if="action">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button"
              v-b-tooltip.hover.v-primary title="Засах"
              v-b-modal.modal-login @click="show(data.item.id)"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
        </template> -->
  </b-table>
  <!-- modal login-->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Хадгалах"
      cancel-title="Болих"
      centered
      title="Гишүүд нэмэх"
      @hide="resetModal"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <b-form>
        <!-- Assignee -->
        <b-form-group
          label="Нэмэх гишүүдээ сонгоно уу"
          label-for="add-child"
        >
          <p>Selected children IDs:{{ childData.childrenIds }}userData.id {{ userData.id }}</p>
          <v-select
            v-model="childData.childrenIds"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="userOptions"
            label="username"
            class="assignee-selector"
            input-id="add-child"
            placeholder="Гишүүд"
            multiple
            :reduce="val => val.id"
            :clearable="false"
            :selectable="val => val.id!==userData.id"
          >
          </v-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormSpinbutton, BDropdown,
    BDropdownItem, BBadge, VBTooltip, BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BFormSpinbutton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    vSelect
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    action: {
      type: Boolean,
      required: true,
    },
    userOptions: {
      type: Array,
      required: false,
    },
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted () {
    // store.dispatch('app-user/fetchUsers', { })
    // .then(response => {
    //   this.userOptions = response.data.response
    // })
  },
  data () {
    const childData = {
      parent: this.userData.id,
      childrenIds: [...this.userData.children.map(x => x.id)],
    };

    console.log('childData:', childData);
    console.log('childrenIds:', childData.childrenIds);

    return {
      childData,
      fields: [
        // 'id',
        {
          key: 'username', label: 'Хэрэглэгчийн нэр',
        },
        {
          key: 'email', label: 'Имэйл',
        },
        {
          key: 'organization_name', label: 'Байгууллагын нэр',
        },
        {
          key: 'team_name', label: 'Багийн нэр',
        },
        {
          key: 'created_by', label: 'Үүсгэсэн',
        },
        {
          key: 'created_at', label: 'Үүсгэсэн огноо',
        },
        {
          key: 'is_active', label: 'Төлөв',
        },
        {
          key: 'actions', label: ''
        }
      ],
      // userOptions: [],
    }
  },
  methods: {
    resetModal () {
      this.childData.addChildrens = []
    },
    handleOk () {
      store.dispatch('app-user/editChildren', this.childData)
        .then(response => {
          this.userData.children = response.data.response
      //     store.dispatch('app-user/fetchChildrenByFilter', { organization_name: this.teamData.organization_name })
      //       .then(response => {
      //         this.items = response.data.response
      //       })
      //     // emit('refetch-data')
      //     // emit('update:is-add-new-organization-sidebar-active', false)
        })
    },
    show (param) {
      console.log('paa', param)
    }
  }
}
</script>